import {
  INFO,
  SUCCESS,
  WARNING,
  ERROR,
} from 'pwa/plugins/ToastPlugin';

const TOAST = 'TOAST';

// Map toast "type" to a Vuetify color
const colorMapping = {
  [INFO]: 'primary',
  [SUCCESS]: 'success',
  [ERROR]: 'error',
  [WARNING]: 'warning',
};

class SnackbarConfig {
  constructor({
    html,
    type = INFO,
    top = true,
    timeout = 3000,
    ...extra
  }) {
    this.html = html;
    this.top = top;
    this.timeout = timeout;
    this.color = colorMapping[type];
    Object.assign(this, extra);
  }
}

export default () => ({
  namespaced: true,

  state: {
    snackbarConfig: null,
  },

  actions: {
    open({ commit }, { html, type = INFO, options = {} }) {
      const snackbarConfig = new SnackbarConfig({ ...options, html, type });
      commit(TOAST, snackbarConfig);
    },
  },

  getters: {
    snackbarConfig: (state) => state.snackbarConfig,
  },

  mutations: {
    [TOAST]: (state, snackbarConfig) => {
      state.snackbarConfig = snackbarConfig;
    },
  },
});
