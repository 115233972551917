/**
 * Toggles the ability to scroll the browser and is used
 * particularly when overlay, modals or dialogs are used.
 */
import { LOCK_SCROLLING, UNLOCK_SCROLLING } from 'common/store/browser';

const mapping = {
  [`browser/${LOCK_SCROLLING}`]: true,
  [`browser/${UNLOCK_SCROLLING}`]: false,
};

export default function scrollLockPlugin(store) {
  store.subscribe((mutation) => {
    if (!(mutation.type in mapping)) {
      return;
    }
    const value = mapping[mutation.type];
    document.querySelector('html').classList.toggle('disable-scroll', value);
    document.querySelector('body').classList.toggle('disable-scroll', value);
  });
}
