/**
 * Dispatches handleAppServiceWorkerLoaded once the app and service worker
 * have both loaded.
 */
export default async function appServiceWorkerLoadedPlugin(store) {
  const appLoaded = new Promise((resolve) => {
    if (store.getters.appState === 'loaded') {
      resolve();
      return;
    }

    const unwatch = store.watch(
      (state, getters) => getters.appState, (appState) => {
        if (appState === 'loaded') {
          resolve();
          unwatch();
        }
      },
    );
  });

  const serviceWorkerLoaded = new Promise((resolve) => {
    if (store.getters['browser/serviceWorkerRegistration']) {
      resolve();
      return;
    }

    const unwatch = store.watch(
      (state, getters) => getters['browser/serviceWorkerRegistration'],
      (reg) => {
        if (reg) {
          resolve();
          unwatch();
        }
      },
    );
  });

  await appLoaded;
  await serviceWorkerLoaded;
  store.dispatch('handleAppServiceWorkerLoaded');
}
