import VuexORM from '@vuex-orm/core';
import { cloneDeep } from 'lodash-es';
import Vuex from 'vuex';

import api from './api';
import auth from './auth';
import {
  comments,
  conversations,
  feeds,
  joins,
  messages,
  notifications,
  plans,
  posts,
  streams,
  tips,
  users,
  userFeeds,
  userRecommendations,
} from './entities';
import flashMessages from './flashMessages';
import gallery from './gallery';
import loadingState from './loadingState';
import pageUnload, { pageUnloadPlugin } from './pageUnload';
import {
  appServiceWorkerLoadedPlugin,
  liveRefreshPlugin,
  onlinePlugin,
  persistedState,
  presencePlugin,
  scrollLockPlugin,
  visibilityPlugin,
} from './plugins';
import pusher from './pusher';
import router from './router';
import s3 from './s3';
import search from './search';
import toast from './toast';

import resci from 'common/resci';
import browser from 'common/store/browser';
import { mergeState } from 'main/store';
import { NetworkError, TimeoutError } from 'pwa/exceptions';
import {
  Album,
  BlogPost,
  Comment,
  Conversation,
  ConversationParticipant,
  Feed,
  Join,
  Message,
  Notification,
  NotificationActor,
  Plan,
  Photoset,
  Post,
  PostFeed,
  PostUserAbout,
  Profile,
  StatusUpdate,
  Stream,
  Tip,
  User,
  UserFeed,
  UserFeedUser,
  UserRecommendation,
  Video,
} from 'pwa/models';

export const SET_APP_FAILED = 'SET_APP_FAILED';
export const SET_APP_LOADED = 'SET_APP_LOADED';
export const SET_APP_LOADING = 'SET_APP_LOADING';
export const SET_ORIGINAL_STATE = 'SET_ORIGINAL_STATE';
export const UPDATE_AVAILABLE = 'UPDATE_AVAILABLE';

export function getConfig() {
  // Load VuexORM
  const database = new VuexORM.Database();
  database.register(Album);
  database.register(BlogPost);
  database.register(Comment, comments());
  database.register(Conversation, conversations());
  database.register(ConversationParticipant);
  database.register(Feed, feeds());
  database.register(Message, messages());
  database.register(Join, joins());
  database.register(NotificationActor);
  database.register(Notification, notifications());
  database.register(Photoset);
  database.register(Plan, plans());
  database.register(Post, posts());
  database.register(PostFeed);
  database.register(PostUserAbout);
  database.register(Profile);
  database.register(StatusUpdate);
  database.register(Stream, streams());
  database.register(Tip, tips());
  database.register(User, users());
  database.register(UserFeed, userFeeds());
  database.register(UserFeedUser);
  database.register(UserRecommendation, userRecommendations());
  database.register(Video);

  const storeConfig = {
    state: {
      appState: 'created',
      originalState: null,
      updateAvailable: false,
    },

    getters: {
      appState: (state) => state.appState,
    },

    actions: {
      /**
       * Initializes app by fetching context data from API
       */
      async loadApp({ commit, dispatch, rootGetters }) {
        await commit(SET_APP_LOADING);
        try {
          await dispatch('api/$getRoutes', null, { root: true });
          await dispatch('auth/$getCurrentUser', null, { root: true });
          // Init resci once user is obtained
          await resci.init(rootGetters['auth/currentUser']);
        } catch (e) {
          await commit(SET_APP_FAILED);
          if (e instanceof NetworkError || e instanceof TimeoutError) {
            return;
          }
          throw e;
        }

        await commit(SET_APP_LOADED);
        dispatch('entities/streams/$fetch');
        const user = rootGetters['auth/currentUser'];
        dispatch('pusher/initChannels', { user });

        if (!user.is_authenticated) {
          return;
        }

        if (user.status !== 'enabled') {
          // This is a hack.
          if (window.location.pathname.startsWith('/p/register/')) return;
          dispatch('auth/toggleRenewSubscriptionSheet', true);
          return;
        }

        // Async calls for authenticated users
        Conversation.dispatch('$fetch');
        Notification.dispatch('$fetch');
        User.dispatch('startPresencePolling');
      },

      /**
       * Post-initialization after app and service worker have both loaded
       */
      async handleAppServiceWorkerLoaded({ dispatch, rootGetters }) {
        const user = rootGetters['auth/currentUser'];

        if (!user.is_authenticated) {
          return;
        }

        try {
          await dispatch('pusher/initBeams');
        } catch (err) {
          // Ignore errors thrown in unsupported browsers such as Safari
          if (err.message.includes('Beams')) {
            return;
          }
          throw err;
        }
      },

      async setOriginalState({ commit }, originalState) {
        await commit(SET_ORIGINAL_STATE, originalState);
      },

      /**
       * Reset store to its original, default state and clear
       * all data.
       */
      async reset({ state }) {
        await this.replaceState(state.originalState);
      },
    },

    mutations: {
      [SET_APP_FAILED]: (state) => { state.appState = 'failed'; },
      [SET_APP_LOADED]: (state) => { state.appState = 'loaded'; },
      [SET_APP_LOADING]: (state) => { state.appState = 'loading'; },
      [SET_ORIGINAL_STATE]: (state, value) => {
        state.originalState = cloneDeep(value);
      },
      [UPDATE_AVAILABLE]: (state) => { state.updateAvailable = true; },
    },

    modules: {
      api: api(),
      auth: auth(),
      browser: browser(),
      flashMessages: flashMessages(),
      gallery: gallery(),
      loadingState: loadingState(),
      pageUnload: pageUnload(),
      pusher: pusher(),
      router: router(),
      s3: s3(),
      search: search(),
      toast: toast(),
    },

    plugins: [
      VuexORM.install(database),
      appServiceWorkerLoadedPlugin,
      liveRefreshPlugin,
      onlinePlugin,
      pageUnloadPlugin,
      presencePlugin,
      scrollLockPlugin,
      visibilityPlugin,
      persistedState,
    ],
  };

  // Grab state data injected from Django
  let state = {
    google_measurement_id: null,
  };

  const stateEl = document.getElementById('vuex-state');
  if (stateEl) {
    state = JSON.parse(stateEl.text);
  }
  // Merge to config object
  const config = mergeState({ config: storeConfig, state });
  return config;
}

export default {};

export function createStore(config) {
  const store = new Vuex.Store(config || getConfig());
  store.dispatch('api/initClient');
  store.dispatch('setOriginalState', store.state);
  return store;
}
