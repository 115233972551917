/**
 * Listens for events to refresh live stream data.
 */
import Stream from 'pwa/models/Stream';

export default function liveRefreshPlugin(store) {
  store.subscribe((mutation) => {
    if (!store.getters['auth/currentUser'].is_authenticated) {
      return;
    }

    const mutations = ['browser/SET_ONLINE', 'browser/SET_VISIBLE'];
    if (!mutations.includes(mutation.type)) {
      return;
    }
    const lastRefresh = store.getters['entities/streams/lastRefresh'];
    if (lastRefresh && (new Date() - lastRefresh) <= 60 * 1000) {
      return;
    }
    Stream.dispatch('$refresh');
  });
}
