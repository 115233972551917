/**
 * Watches the online/offline window events and calls
 * the browser Vuex actions to capture the app state.
 */
export default function onlinePlugin(store) {
  window.addEventListener('online', () => {
    store.dispatch('browser/setOnline');
  });

  window.addEventListener('offline', () => {
    store.dispatch('browser/setOffline');
  });
}
