import * as PusherPushNotifications from '@pusher/push-notifications-web';
import Cookies from 'js-cookie';
import Vue from 'vue';

import PusherJS from 'contrib/pusher-js';
import {
  Conversation,
  Message,
  Notification,
  Stream,
} from 'pwa/models/';

const SET_BEAMS_CLIENT = 'SET_BEAMS_CLIENT';
const SET_CHANNELS_CLIENT = 'SET_CHANNELS_CLIENT';
const SET_LIVE_CHANNEL = 'SET_LIVE_CHANNEL';
const SET_PRIVATE_CHANNEL = 'SET_PRIVATE_CHANNEL';

/**
 * Pusher Vuex module
 */
export default () => ({
  namespaced: true,

  state: {
    beamsClient: null,
    channelsClient: null,
    liveChannel: null,
    privateChannel: undefined,
  },

  actions: {
    /**
     * Instantiates Pusher Beams client
     */
    async initBeams({ commit, dispatch, rootGetters }) {
      if (!PUSHER_BEAMS_INSTANCE_ID) {
        Vue.$log.error(
          'Could not init Pusher Beams. Please set the following environment '
          + 'variables with valid credentials: PUSHER_BEAMS_INSTANCE_ID and '
          + 'PUSHER_BEAMS_SECRET_KEY',
        );
        return null;
      }
      const user = rootGetters['auth/currentUser'];

      const serviceWorkerRegistration = rootGetters[
        'browser/serviceWorkerRegistration'
      ];
      const beamsTokenProvider = new PusherPushNotifications.TokenProvider({
        url: rootGetters['api/routes'].pusher_beams_auth,
        credentials: 'include',
      });

      const beamsClient = new PusherPushNotifications.Client({
        instanceId: PUSHER_BEAMS_INSTANCE_ID,
        serviceWorkerRegistration,
      });

      try {
        await beamsClient.start();
      } catch (e) {
        if (e.message.includes('permission')) {
          return null;
        }
        throw e;
      }

      const userId = await beamsClient.getUserId();
      if (userId && userId !== String(user.id)) {
        await beamsClient.stop();
        return dispatch('initBeams');
      }

      await beamsClient.setUserId(String(user.id), beamsTokenProvider);
      return commit(SET_BEAMS_CLIENT, beamsClient);
    },

    async stopBeams({ commit, getters }) {
      const { beamsClient } = getters;

      if (!beamsClient) {
        return;
      }

      await beamsClient.clearAllState();
      await beamsClient.stop();

      commit(SET_BEAMS_CLIENT, null);
    },

    /**
     * Instantiates a PusherJS Channels client and if the user is
     * authenticated, subscribes to their private channel
     */
    initChannels({ commit, rootGetters }, { user, Pusher = PusherJS }) {
      if (!PUSHER_CHANNELS_KEY || !PUSHER_CHANNELS_CLUSTER) {
        Vue.$log.error(
          'Could not init Pusher Channels. Please set the following '
          + 'environment variables with valid credentials: '
          + 'PUSHER_CHANNELS_APP_ID, PUSHER_CHANNELS_KEY, '
          + 'PUSHER_CHANNELS_SECRET and PUSHER_CHANNELS_CLUSTER',
        );
        return;
      }
      const authEndpoint = rootGetters['api/routes'].pusher_channels_auth;
      const csrfCookieName = rootGetters['api/csrfCookieName'];

      const client = new Pusher(PUSHER_CHANNELS_KEY, {
        authEndpoint,
        cluster: PUSHER_CHANNELS_CLUSTER,
        auth: {
          headers: { 'X-CSRFToken': Cookies.get(csrfCookieName) },
        },
      });

      commit(SET_CHANNELS_CLIENT, client);

      if (user.is_authenticated) {
        const channel = client.subscribe(`private-${user.id}`);
        channel.bind(
          'conversation_typing',
          (data) => Conversation.dispatch('userTyping', data),
        );
        channel.bind(
          'new_message',
          (data) => Message.dispatch('newMessageEvent', data),
        );
        channel.bind(
          'new_notification',
          (data) => Notification.dispatch('newNotificationEvent', data),
        );
        commit(SET_PRIVATE_CHANNEL, channel);
      }

      const liveChannel = client.subscribe('live');
      commit(SET_LIVE_CHANNEL, liveChannel);

      liveChannel.bind(
        'stream_started',
        (data) => Stream.dispatch('streamStartedEvent', data),
      );

      liveChannel.bind(
        'stream_ended',
        (data) => Stream.dispatch('streamEndedEvent', data),
      );
    },
  },

  getters: {
    beamsClient: (state) => state.beamsClient,
    channelsClient: (state) => state.channelsClient,
    liveChannel: (state) => state.liveChannel,
    privateChannel: (state) => state.privateChannel,
  },

  mutations: {
    [SET_LIVE_CHANNEL]: (state, channel) => {
      state.liveChannel = channel;
    },

    [SET_PRIVATE_CHANNEL]: (state, channel) => {
      state.privateChannel = channel;
    },

    [SET_BEAMS_CLIENT]: (state, client) => {
      state.beamsClient = client;
    },

    [SET_CHANNELS_CLIENT]: (state, client) => {
      state.channelsClient = client;
    },
  },
});
