/**
 * Calculates the aspect ratio based on provided width
 * and height up to a minimum of `4:5`
 */
export const getConstrainedAspectRatio = (x, y) => Math.max(x / y, 4 / 5);

/**
 * Convert a multiplier descriptor srcset (url 1x, url 2x, ...) to
 * a width descriptor srcset + sizes, based on the 1x width and height,
 * for use with full screen width gallery, allowing the browser to use
 * the smallest image that fills the viewport width or height.
 */
const _multiplierSrcsetRegex = /\s*(\S+)\s+(\d+)x\s*,?\s*/g;
export const convertToFullScreenResponsive = (
  multiplierSrcset,
  width1x,
  height1x,
) => {
  if (!multiplierSrcset || !width1x || !height1x) {
    return {};
  }
  const srcset = [];
  const sizes = [];
  const matches = Array.from(multiplierSrcset.matchAll(_multiplierSrcsetRegex));
  const lastMatchIndex = matches.length - 1;
  matches.forEach(
    ([, url, multiplier], index) => {
      const m = parseInt(multiplier, 10);
      const width = width1x * m;
      const height = height1x * m;
      srcset.push(`${url} ${width}w`);
      if (index !== lastMatchIndex) {
        sizes.push(
          `(max-width: ${width}px), (max-height: ${height}px) ${width}px`,
        );
      } else {
        // Last is always unconditional default.
        sizes.push(`${width}px`);
      }
    },
  );

  return {
    sizes: sizes.join(', '),
    // Fallback to orig passed in if nothing parsed
    srcset: srcset.join(', ') || multiplierSrcset,
  };
};

export default {};
