import axios from 'axios';

const RESET = 'RESET';

export default () => ({
  namespaced: true,

  getters: {
    cancelSource: (state) => state.cancelSource,
  },

  actions: {
    async findUsers(
      { commit, getters, rootGetters },
      { term } = {},
    ) {
      await commit(RESET);
      const client = rootGetters['api/client'];
      const url = rootGetters['api/routes'].search;
      const params = { term, type: 'users' };
      const cancelToken = getters.cancelSource.token;
      return client.get(url, { cancelToken, params });
    },
  },

  state: {
    cancelSource: axios.CancelToken.source(),
  },

  mutations: {
    [RESET]: (state) => {
      state.cancelSource.cancel('Search cancelled by new term');
      state.cancelSource = axios.CancelToken.source(); // Reset token
    },
  },
});
