const OPEN_DIALOG = 'OPEN_DIALOG';
const CONFIRM = 'CONFIRM';
const CANCEL = 'CANCEL';
const ADD_LISTENER = 'ADD_LISTENER';
const REMOVE_LISTENER = 'REMOVE_LISTENER';

export default () => ({
  namespaced: true,

  state: {
    listening: false,
    showingDialog: false,
    leaveConfirmed: false,
  },

  getters: {
    showingDialog: (state) => state.showingDialog,

    listening: (state) => state.listening,
  },

  actions: {
    async openDialog({ dispatch, commit, state }) {
      if (!state.listening) {
        return Promise.resolve();
      }
      commit(OPEN_DIALOG);

      const checkAnswer = (resolve) => {
        if (state.leaveConfirmed || !state.showingDialog) {
          resolve(state.leaveConfirmed);
          if (state.leaveConfirmed) {
            dispatch('removeListener');
          }
        } else {
          // Keep checking until the user has responded to dialog
          setTimeout(() => checkAnswer(resolve), 100);
        }
      };

      return new Promise(checkAnswer);
    },

    confirm({ commit }) {
      commit(CONFIRM);
    },

    cancel({ commit }) {
      commit(CANCEL);
    },

    addListener({ commit }) {
      commit(ADD_LISTENER);
    },

    removeListener({ commit }) {
      commit(REMOVE_LISTENER);
    },
  },

  mutations: {
    [OPEN_DIALOG]: (state) => {
      state.showingDialog = true;
    },

    [CONFIRM]: (state) => {
      state.showingDialog = false;
      state.leaveConfirmed = true;
    },

    [CANCEL]: (state) => {
      state.showingDialog = false;
    },

    [ADD_LISTENER]: (state) => {
      state.listening = true;
    },

    [REMOVE_LISTENER]: (state) => {
      state.listening = false;
      state.leaveConfirmed = false;
    },
  },
});

export function beforeUnloadHandler(event) {
  event.preventDefault();
  event.returnValue = '';
}

export function pageUnloadPlugin(store) {
  const args = ['beforeunload', beforeUnloadHandler];

  store.subscribe((mutation) => {
    if (mutation.type === `pageUnload/${ADD_LISTENER}`) {
      window.addEventListener(...args);
    }
    if (mutation.type === `pageUnload/${REMOVE_LISTENER}`) {
      window.removeEventListener(...args);
    }
  });
}
