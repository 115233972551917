import axios from 'axios';

export default () => ({
  namespaced: true,

  getters: {
    client: (state) => state.client,
  },

  actions: {
    /**
     * Bulk upload files to S3. Promise fulfils once
     * all uploads complete successfully and rejects at the
     * first failure.
     */
    async uploadFiles({ getters, rootGetters }, files) {
      const apiClient = rootGetters['api/client'];
      const signingUrl = rootGetters['api/routes'].sign_urls;

      const paths = files.map(({ file }) => file.name);
      const response = await apiClient.post(signingUrl, { paths });
      const { urls } = response.data;

      const { client } = getters;

      const requests = urls.map((signedUrl, index) => {
        const formData = new FormData();
        const { fields } = signedUrl;
        Object.keys(fields).forEach((key) => {
          formData.append(key, fields[key]);
        });
        formData.append('file', files[index].file);

        return client.post(signedUrl.url, formData);
      });

      await Promise.all(requests);
      return urls.map((url, index) => ({
        key: url.fields.key,
        original_filename: files[index].file.name,
      }));
    },
  },

  state: {
    client: axios.create({
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
  },
});
