/**
 * Refresh user presences.
 */
import {
  SET_HIDDEN,
  SET_OFFLINE,
  SET_ONLINE,
  SET_VISIBLE,
} from 'common/store/browser';
import { User } from 'pwa/models';

export default function presencePlugin(store) {
  store.subscribe(async (mutation) => {
    const { type } = mutation;
    if ([`browser/${SET_ONLINE}`, `browser/${SET_VISIBLE}`].includes(type)) {
      User.dispatch('startPresencePolling');
    }
    if ([`browser/${SET_OFFLINE}`, `browser/${SET_HIDDEN}`].includes(type)) {
      User.dispatch('stopPresencePolling');
    }
  });
}
