const SET_ROUTE = 'SET_ROUTE';

export default () => ({
  namespaced: true,

  getters: {
    route: (state) => state.route,
    history: (state) => state.history,
  },

  actions: {
    async back({ getters }, $router) {
      if (getters.history.length < 2) {
        return $router.push({ name: 'index' });
      }
      return $router.back();
    },

    setRoute({ commit }, route) {
      return commit(SET_ROUTE, route);
    },
  },

  state: () => ({
    history: [],
    route: null,
  }),

  mutations: {
    [SET_ROUTE]: (state, route) => {
      state.history.push(route);
      state.route = route;
    },
  },
});
