import { v4 as uuid4 } from 'uuid';
import Vue from 'vue';

const SET_LOADING_PROMISE = 'SET_LOADING_PROMISE';
const CLEAR_LOADING_PROMISE = 'CLEAR_LOADING_PROMISE';

export default () => ({
  namespaced: true,

  state: {
    promises: {},
  },

  actions: {
    async activate({ commit }, promise) {
      const id = uuid4(); // Give this promise an identifier
      commit(SET_LOADING_PROMISE, { id, promise });
      try {
        await promise;
      } catch (e) {
        Vue.$log.error(e);
      } finally {
        commit(CLEAR_LOADING_PROMISE, { id });
      }
    },
  },

  getters: {
    active: (state) => Boolean(Object.keys(state.promises).length),
  },

  mutations: {
    [SET_LOADING_PROMISE]: (state, { id, promise }) => {
      state.promises = { ...state.promises, [id]: promise };
    },

    [CLEAR_LOADING_PROMISE]: (state, { id }) => {
      const promises = { ...state.promises };
      delete promises[id];
      state.promises = promises;
    },
  },
});
