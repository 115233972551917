import createPersistedState from 'vuex-persistedstate';

export default function persistedStatePlugin(store) {
  return createPersistedState({
    paths: [
      'entities.feeds.selectedOrderingFilter',
      'entities.feeds.selectedUserTypeFilters',
      'entities.feeds.selectedSotdUserTypeFilters',
      'entities.posts.defaultPrivacy',
    ],
  })(store);
}
